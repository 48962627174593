import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import data from './dates.json'; // Import your JSON data
import Tooltip from '@mui/material/Tooltip'; // Import MUI Tooltip

// Utility function to convert a date to the day of the year
const getDayOfYear = (dateStr) => {
    const date = new Date(dateStr);
    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date - start + (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
};

// Utility to get all days in the year with an index for each day
const getDaysInYear = (year) => {
    const daysInYear = [];
    for (let d = new Date(year, 0, 1), i = 0; d.getFullYear() === year; d.setDate(d.getDate() + 1), i++) {
        daysInYear.push({ date: new Date(d), index: i + 1 }); // Assign index to each day
    }
    return daysInYear;
};

function App() {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to the current year
    const daysInYear = getDaysInYear(selectedYear); // Get all days in the selected year

    // Refs for both the scrollable timeline container (header) and event rows (body)
    const timelineRef = useRef(null);
    const bodyRef = useRef(null);

    // Scroll to today's date on page load
    useEffect(() => {
        const today = new Date();
        const dayOfYearToday = getDayOfYear(today);
        const tileWidth = 60; // Assuming each tile is 60px wide

        // Scroll to the position of today's date in both the header and body
        if (timelineRef.current && bodyRef.current) {
            timelineRef.current.scrollLeft = dayOfYearToday * tileWidth;
            bodyRef.current.scrollLeft = dayOfYearToday * tileWidth;
        }
    }, [selectedYear]);

    // Synchronize scroll between the header and body
    const syncScroll = (e) => {
        const scrollLeft = e.target.scrollLeft;
        if (timelineRef.current !== e.target) {
            timelineRef.current.scrollLeft = scrollLeft;
        }
        if (bodyRef.current !== e.target) {
            bodyRef.current.scrollLeft = scrollLeft;
        }
    };

    // Filter events for the selected year by checking the start date
    const yearEvents = data.events.filter(event => new Date(event.start).getFullYear() === selectedYear);

    // Helper function to check if a day index falls within an event range
    const isDayWithinEvent = (dayIndex, event) => {
        const startDay = getDayOfYear(event.start);
        const endDay = getDayOfYear(event.end);
        return dayIndex >= startDay && dayIndex <= endDay;
    };

    return (
        <div className="kanban-view">
            {/* X-axis label */}
            <div className="x-axis-label">Term Dates</div>

            <div className="kanban-header">
                {/* Scrollable timeline for dates (header) */}
                <div className="master-dates" ref={timelineRef} onScroll={syncScroll}>
                    {daysInYear.map(({ date, index }) => {
                        const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, etc.
                        const dayOfMonth = date.getDate();
                        const isMonday = dayOfWeek === 1; // Check if it's Monday
                        const isFirstDayOfMonth = dayOfMonth === 1; // Check if it's the first day of the month

                        return (
                            <div
                                key={index}
                                className={`day-cell ${isMonday ? 'monday-border' : ''} ${isFirstDayOfMonth ? 'month-border' : ''}`}
                            >
                                <div className="day-of-week">{date.toLocaleDateString('en-GB', { weekday: 'short' })}</div>
                                <div className="day-of-month">{date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })}</div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Scrollable body for events (rows) */}
            <div className="kanban-body" ref={bodyRef} onScroll={syncScroll}>
                {data.schools.map((school, schoolIndex) => (
                    <div key={schoolIndex} className="school-block">
                        {/* School Name Row */}
                        <div className="school-name-row">
                            <div className="school-name">{school}</div>
                        </div>
                        {/* Event Row */}
                        <div className="school-row">
                            <div className="school-timeline">
                                {daysInYear.map(({ date, index }) => {
                                    // Check if the current day is within any event for the current school
                                    const eventForDay = yearEvents.find(
                                        event => event.school === school && isDayWithinEvent(getDayOfYear(date), event)
                                    );

                                    return (
                                        <Tooltip
                                            key={index}
                                            title={eventForDay ? `Term ${eventForDay.term}` : ''}
                                            arrow
                                            disableHoverListener={!eventForDay} // Only show tooltip if there is an event
                                        >
                                            <div
                                                className={`day-cell ${eventForDay ? 'highlight' : ''}`}
                                            >
                                                {/*{date.toLocaleDateString('en-GB', {day: 'numeric', month: 'short'})}*/}
                                            </div>
                                        </Tooltip>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default App;
